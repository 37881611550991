export const KEYWORDS = [
  "minimalism",
  "illustration",
  "minimal digital painting",
  "simple tattoo design for girl",
  "minimalism art drawing",
  "minimalist line art",
  "illustration sketch",
  "poster illustration",
  "digital sketching",
  "poster art",
  "colourful artwork",
  "illustgram",
  "singapore artist",
  "singapore painting",
  "sgartist",
  "挂画",
  "简约",
  "插畫",
  "插畫師",
  "簡約插畫",
  "創作 插畫日記",
  "品牌設計",
  "香港插畫",
  "線畫",
  "台灣插畫",
  "卡通",
  "插畫家",
  "電繪",
  "設計",
  "塗鴉",
  "",
];

export const getRandomKeywords=(arr:string[]=KEYWORDS)=>{
    const maxIndex = arr.length -1
    for (let currentIndex:number = maxIndex; currentIndex>0; currentIndex--){
        const newIndex = Math.floor(Math.random()*(currentIndex+1)) // +1 becaues index 4 is length 5
        const temp = arr[currentIndex];
        arr[currentIndex] = arr[newIndex];
        arr[newIndex] = temp;
    }
    const outArr = arr.slice(0, Math.floor(arr.length * 0.5))
    return outArr.join(" ")
}