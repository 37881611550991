import * as React from "react";
import Box from "@mui/material/Box";
import { ArtWork, ArtworkInterface } from "./Artwork";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ARTWORKS_BY_TAGS, GET_ALL_ARTWORKS } from "../graphql-queries";
import { Grid, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import { getDefaultArtworks } from "./defaultList";
import { getFontVariantEastAsian } from "../utils/language-util";
import { useLanguage } from "../contexts/LanguageContext";
import { ARTWORKS_EMPTY } from "../constants/language-errors";

interface ArtworksProps{
  artworks: ArtworkInterface[],
  error:any,
  searching: boolean,
  handleOnClickTag:Function,
}

export const ArtWorks = (props: ArtworksProps) => {
  const [errorMsg, setErrorMsg] = React.useState("");
  const { language, facadeLanguage } = useLanguage();
  React.useEffect(()=>{
    if(!props.searching ){
      if (props.artworks.length > 0) {
        setErrorMsg("");
      } else {
        setErrorMsg(ARTWORKS_EMPTY[language]);
      }
    }
  },[props.artworks])

  if (props.searching) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <CircularProgress sx={{ color: "black" }} />
        </Box>
      </div>
    );
  } else if (props.error) {
    return (
      <Alert severity="warning" sx={{ width: "40%", margin: "auto" }}>
        {" "}
        {props.error.message}{" "}
      </Alert>
    );
  } else {
    return (
      <div>
        <Box
          sx={{
            flexGrow: 1,
            paddingTop: 5,
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <Grid container spacing={3}>
            {errorMsg && (
              <Alert severity="warning" sx={{ width: "40%", margin: "auto", fontVariantEastAsian:getFontVariantEastAsian(facadeLanguage)}}>
                {" "}
                {errorMsg}{" "}
              </Alert>
            )}
            {props.artworks
              ?.slice()
              .sort((a, b) => b.heartCount - a.heartCount)
              .map((item, index) => {
                return (
                  <Grid item xs={12} md={4} key={index}>
                    {item.description && (
                      <ArtWork
                        artworkData={item}
                        handleOnClickTag={props.handleOnClickTag}
                        key={index}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </div>
    );
  }
};

export const ArtworksDefault = () => {
  const { facadeLanguage } = useLanguage();
  const artworks: ArtworkInterface[] = getDefaultArtworks();

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: 5,
        marginLeft: 2,
        marginRight: 2,
        fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
      }}
    >
      <Grid container spacing={3}>
        {artworks
          ?.slice()
          .sort((a, b) => b.heartCount - a.heartCount)
          .map((item, index) => {
            return (
              <Grid item xs={12} md={4} key={index}>
                {item.description && (
                  <ArtWork
                    artworkData={item}
                    handleOnClickTag={() => {}}
                    key={index}
                  />
                )}
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
