import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { getDoyoumean } from "../api";
import Link from "@mui/material/Link";
import { getCategories } from "../api";
import DevicesIcon from "@mui/icons-material/Devices";
import BrushIcon from "@mui/icons-material/Brush";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PetsIcon from "@mui/icons-material/Pets";
import { useLanguage } from "../contexts/LanguageContext";
import { getFontVariantEastAsian } from "../utils/language-util";
import { SEARCH_PLACEHOLDER } from "../constants/langauge-others";

interface SearchProps {
  handleOnclickSearch: any;
  handleOnclickCategories: any;
  artworkData: Array<object>;
}

interface FilterIconProps {
  handleOnclickCategories: any;
  category: string;
  selectedCategory: string | undefined;
}

const ICONS_MAP: Record<string, React.ReactElement> = {
  watercolour: <BrushIcon sx={{ color: "black" }} />,
  design: <TipsAndUpdatesIcon sx={{ color: "black" }} />,
  clay: <PetsIcon sx={{ color: "black" }} />,
  digital: <DevicesIcon sx={{ color: "black" }} />,
  水彩: <BrushIcon sx={{ color: "black" }} />,
  设计: <TipsAndUpdatesIcon sx={{ color: "black" }} />,
  其他的: <PetsIcon sx={{ color: "black" }} />,
  数码: <DevicesIcon sx={{ color: "black" }} />,
};

const FilterIcons = (props: FilterIconProps) => {
  const { language, facadeLanguage } = useLanguage();

  const getIcon = (category: string) => {
    const isActive = props.selectedCategory === category;
    const icon = ICONS_MAP[category] || <DevicesIcon sx={{ color: "black" }} />;
    return React.cloneElement(icon, {
      sx: {
        color: "black",
        // color: isActive ? "#82A197" : "black",
        // "&:hover": {
        //   color: "#82A197",
        // },
      },
    });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        marginRight: { xs: 0.5, sm: 3 },
        cursor: "pointer",
        transition: "transform 0.3s ease",
        "&:hover": {
          // color: "#82A197",
          transition: "background-color 0.3s ease, transform 0.3s ease",
          transform: "scale(1.1)",
        },
      }}
    >
      <IconButton
        value={props.category}
        disableRipple
        onClick={(event) => {
          props.handleOnclickCategories(event.currentTarget.value);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
        sx={{
          marginRight: { xs: 0.5, sm: 3 },
          cursor: "pointer",
          "&:hover": {
            color: "#82A197",
            transition: "background-color 0.3s ease, transform 0.3s ease",
            transform: "scale(1.1)",
          },
        }}
      >
        {getIcon(props.category)}
        <Typography
          variant="caption"
          paragraph
          align="justify"
          sx={{
            fontWeight: 300,
            fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
          }}
        >
          {[props.category]}
        </Typography>
      </IconButton>
    </Box>
  );
};

export const SearchBar = (props: SearchProps) => {
  const { language, facadeLanguage } = useLanguage();
  const [keyword, setKeyword] = React.useState("");
  const [submittedKeyword, setSubmittedKeyword] = React.useState("");
  const [keyError, setKeyError] = React.useState("");
  const [suggestion, setSuggestion] = React.useState("");
  const [categoryChoices, setCategoryChoices] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState<
    string | undefined
  >(undefined);
  const { handleOnclickSearch, handleOnclickCategories, artworkData } = props;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (keyword === "") {
      setKeyError("Enter something please..");
    } else {
      setSubmittedKeyword(keyword.trim().toLocaleLowerCase());
      handleOnclickSearch(keyword.trim().toLocaleLowerCase());
      setKeyError("");
    }
    setKeyword("");
  };

  const _handleOnclickCategories = (value: any) => {
    setSelectedCategory(value);
    handleOnclickCategories(value);
  };

  // Get suggestion if no result return for a given keyword. Only check if artworkData is not undefined as undefined indicates initial load. Initial load no need for
  React.useEffect(() => {
    if (artworkData.length > 0) {
      setSubmittedKeyword("");
    } else if (submittedKeyword !== "" && artworkData.length == 0) {
      getDoyoumean(submittedKeyword).then((recievedData) => {
        if (recievedData !== undefined) {
          setSuggestion(Object.values(recievedData).join(" "));
        }
      });
    } else if (submittedKeyword === "") {
      setSuggestion("");
    }
  }, [submittedKeyword, props.artworkData]);

  // show Categories
  React.useEffect(() => {
    getCategories(language).then((data) => {
      setCategoryChoices(data);
    });
  }, [language]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: 2,
        paddingBottom: 1,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >

      <Box sx={{ width:{xs:"80%", md:"50%"} }}> 
        <Paper
          component="form"
          variant="outlined"
          elevation={0}
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            alignItems: "center",
            // fontVariantEastAsian:getFontVariantEastAsian(facadeLanguage)
          }}
        >
          <InputBase
            sx={{ flex: 1, mr: 1, paddingLeft: 1 }}
            placeholder={SEARCH_PLACEHOLDER[language]}
            value={keyword}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
          />
          <IconButton type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {keyError && (
          <Typography variant="subtitle2" color="#CA8787">
            {keyError}
          </Typography>
        )}

        {suggestion && (
          <div style={{ marginTop: 10 }}>
            <Typography variant="subtitle2">
              {"Are you looking for : "}
              <Link
                component="button"
                onClick={() => {
                  handleOnclickSearch(suggestion);
                  setSuggestion("");
                  setKeyword("");
                }}
              >
                {suggestion}
              </Link>
            </Typography>
          </div>
        )}
        {categoryChoices.length > 0 && (
          <Box display="flex" marginTop={2} justifyContent={"center"}>
            {categoryChoices.map((category, index) => (
              <FilterIcons
                handleOnclickCategories={_handleOnclickCategories}
                category={category}
                selectedCategory={selectedCategory}
                key={index}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
