import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import { useLanguage, AVAILABLE_LANGUAGE } from "../contexts/LanguageContext";
import { APPBAR_LANGUAGE } from "../constants/langauge-others";
import { APPBAR_TABS } from "../constants/langauge-others";
import LanguageIcon from "@mui/icons-material/Language";
import { getFontVariantEastAsian } from "../utils/language-util";
import { getRandomKeywords } from "../constants/general";

const LangaugeSelector = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onCloseIcon=()=>{
    setAnchorEl(null);
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <LanguageIcon sx={{color:'black'}}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {AVAILABLE_LANGUAGE.map((_language) => (
          <MenuItem
            key={_language}
            onClick={() => {
              props.setLanguage(_language);
              onCloseIcon()
            }}
            selected={_language === props.facadeLanguage}
          >
            {APPBAR_LANGUAGE[_language]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const AppAppBar = (props: any) => {
  const navigate = useNavigate();
  const { language, facadeLanguage, setLanguage } = useLanguage();

  const handleOnClickHome = () => {
    if (window.location.pathname === "/") {
      // Force a full page reload
      window.location.reload();
    } else {
      // Navigate to the home route
      navigate("/");
    }
  };

  const handleOnclickContact = () => {
    navigate("/contact");
  };

  const handleOnClickTechStack = () => {
    navigate("/stack");
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
      <AppBar position="static">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            color: "black",
            width: "100%",
            cursor: "pointer",
            
            // transition: 'all 0.4s ease',
            // height: props.size === 'small' ? '50%' : '100%', // Adjust heights as needed
          }}
        >
          <img
            src="images/logo.png"
            style={{
              width: "70%",
              marginTop: 20,
              height: "100%",
              objectFit: "cover",
            }}
            alt={getRandomKeywords()}
            onClick={handleOnClickHome}
          />
          <LangaugeSelector facadeLanguage={facadeLanguage} setLanguage={setLanguage} />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            color: "black",
            
          }}
        >
          <Tabs
            value={window.location.pathname}
            textColor="inherit"
            indicatorColor="primary"
            variant="standard"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#31363F",
              },
              
            }}
          >
            <Tab
              value="/"
              label={APPBAR_TABS[language]["art"]}
              onClick={handleOnClickHome}
              sx={{
                fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)", // Slightly enlarge on hover
                },
              }}
            />
            <Tab
              value="/stack"
              label={APPBAR_TABS[language]["stack"]}
              onClick={handleOnClickTechStack}
              sx={{
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)", // Slightly enlarge on hover
                },
                fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
              }}
            />
            <Tab
              value="/contact"
              label={APPBAR_TABS[language]["contact"]}
              onClick={handleOnclickContact}
              sx={{
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)", // Slightly enlarge on hover
                },
                fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
              }}
            />
          </Tabs>
        </Box>
      </AppBar>
    </Box>
  );
};
