import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { getFontVariantEastAsian } from "../utils/language-util";
import { useLanguage } from "../contexts/LanguageContext";
import { getRandomKeywords } from "../constants/general";

export interface ArtworkInterface {
  title: string;
  description: String;
  imagePath: String;
  tags: [{ name: string }];
  heartCount: number
}

interface ArtWorkProps {
  artworkData: ArtworkInterface;
  handleOnClickTag: Function;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const ArtworkDialog = (props: any) => {
  const {facadeLanguage} =useLanguage()
  const handleClose = () => {
    props.setOpenDialog(false);
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={props.openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 , fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage)}} id="dialog-title">
          {props.artworkData.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <CardMedia
            component="img"
            image={`/artworks/${props.artworkData.imagePath}`}
            alt={getRandomKeywords()}
          />
          <Typography sx = {{fontVariantEastAsian:getFontVariantEastAsian(facadeLanguage)}}>{props.artworkData.description}</Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export const ArtWork = (props: ArtWorkProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const {facadeLanguage} = useLanguage()

  return (
    <div style={{ display: "flex", justifyContent: "center" }} >
      {props.artworkData && (
        <ArtworkDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          artworkData={props.artworkData}
        />
      )}
      <Card sx={{
         maxWidth: 500, 
         maxHeight: 620,
         transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)', // Slightly enlarge the card on hover
          boxShadow: 3, // Apply a shadow effect
        },
        fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage)
         }} elevation={1} >
        <CardMedia
          component="img"
          image={`/artworks/${props.artworkData.imagePath}`}
          alt={getRandomKeywords()}
          loading="lazy"
          style={{
            objectFit: "contain",
            width: "100%",
            height: 500,
            display: "flex",
            justifyContent: "center",
            paddingTop:5,
            paddingLeft:5,
            paddingRight:5,
          }}
          onClick={() => setOpenDialog(true)}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary" sx={{fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage)}} >
            {props.artworkData.title}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {props.artworkData.tags?.map((tag, index) => (
            <Button
            key={index}
              variant="text"
              value={tag["name"]}
              sx={{
                fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
                color: "black",
                fontSize: "0.6rem", // Adjust the font size as needed
                padding: "1px 4px", // Adjust the padding as needed
                minWidth: 0, // Ensure the button doesn't have a minimum widt
                "&:hover": {
                  color:"white",
                  backgroundColor: "#82A197", // Optional: Light grey background on hover
                },
              }}
              onClick={(event) => {
                props.handleOnClickTag(event.currentTarget.value);
              }}
            >
              #{tag["name"]}
            </Button>
          ))}
        </CardActions>
      </Card>
    </div>
  );
};
