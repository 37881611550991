import axios, { AxiosError, AxiosResponse } from 'axios'


const getRequest = async (apiName:string, paramsObj:object) => {
    try{
        const response:AxiosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/${apiName}`, {params:paramsObj})
        return response.data
    }catch (error){
        return {}
    }
};

const postReqeust= async (apiName:string, data: object)=>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/${apiName}`, data)
        return {"status":200,"message":"OK"}
    }catch(error:any){
        return {"status": error.response.status, "message": error.message}
    }
}

export const getDoyoumean=(query:string)=>{
    return getRequest("getDoyoumean", {"query": query})
}

export const postSubmitContract=(formData:object)=>{
    return postReqeust("submitContact", formData)
}

export const getHealth=()=>{
    return getRequest("getHealth",{})
}

export const getCategories=(language:string)=>{
    return getRequest("getCategories", {"language":language})
}