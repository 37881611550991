import { gql } from '@apollo/client';

export const GET_ALL_ARTWORKS = gql`
    query GetAllArtworks($language:String){
        allArtworks(language:$language){
            title
            description
            imagePath
            heartCount
            tags{ name }
    }
  }
`;

export const GET_ARTWORKS_BY_TAGS = gql`
query GetArtworkByTags($query:String, $language:String){
    searchArtworkTags(query:$query, language:$language){
        title
        description
        imagePath
        heartCount,
        tags {
          name
    }
  }
}
`;

export const GET_ARTWORKS_BY_CATEGORIES =gql`
query GetArtworkByCategories($query:String, $language:String){
    searchArtworkCategories(query:$query, language:$language){
        title
        description
        imagePath
        heartCount,
        tags {
          name
    }
  }
}
`;

export const GET_ARTWORKS = gql`
query GetArtworks($query:String, $language:String){
    searchArtwork(query:$query, language:$language){
        title
        description
        imagePath
        heartCount
        tags {
          name
    }
  }
}
`;