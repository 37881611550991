import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider , useLocation , Outlet} from 'react-router-dom';
import Home from './pages/Home'
import Contact from './pages/Contact';
import { lightTheme, darkTheme } from './themes/themes';
import { ThemeProvider } from '@mui/material/styles';
import {ErrorComponent} from './components/Error'
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TechStack } from './pages/TechStack';
import { LangaugeContextProvider } from './contexts/LanguageContext';
import ReactGA from 'react-ga4';


const GAPageViewTrack=()=>{
  const location = useLocation()
  React.useEffect(()=>{
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  },[location])
  return null
}

// Layout component that wraps all routes
interface LayoutProps {
  children: React.ReactElement;
}

function Layout() {
  return (
    <div>
      <GAPageViewTrack />
      <Outlet /> 
    </div>
  );
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path : "/", element : <Home/>, errorElement:<ErrorComponent errorHeader="OPPS.." errorMessage="Wrong path? deadend over here:)"/>,},
      { path : "/contact", element:<Contact/>},
      {path: "/stack", element:<TechStack/>}
    ],
  },
]);
const router2 = createBrowserRouter([
  { path : "/", element : <Home/>, errorElement:<ErrorComponent errorHeader="OPPS.." errorMessage="Wrong path? deadend over here:)"/>,},
  { path : "/contact", element:<Contact/>},
  {path: "/stack", element:<TechStack/>}
])


const App= () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
  const theme = prefersDarkMode ? darkTheme : lightTheme;

  // setup google analytics
  React.useEffect(()=>{
    ReactGA.initialize("G-0RQW0R2N2H");
    ReactGA.send("pageview");
  },[])

    return (
      <ThemeProvider theme = {lightTheme}>
        <LangaugeContextProvider>
        <CssBaseline>
          
        <RouterProvider router={router} />
        
        </CssBaseline>
        </LangaugeContextProvider>
      </ThemeProvider>
        
    );
}

export default App;