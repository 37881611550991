import React, {createContext, ReactNode, useContext} from 'react'


export const AVAILABLE_LANGUAGE:string[] = ['english','chinese','chinese_traditional']


const facadeLangaugeMap:{[key:string]:string}= {
    "chinese_traditional" : "chinese" //facacde lanuage is tradtional but actual is chinese
}

interface LanguageContextType{
    language:string;
    facadeLanguage : string|undefined
    setLanguage: (newLanguage: string, isFacadeLanguage:boolean)=>void;
}

const LanguageConetxt = createContext<LanguageContextType|undefined>(undefined)

interface LanguageProviderProps {
    children: ReactNode; 
  }

export const LangaugeContextProvider: React.FC<LanguageProviderProps>=({children})=>{
    const [language, changeLanguage] = React.useState<string>("english")
    const [facadeLanguage, setFacadeLanguage] = React.useState<string|undefined>()
    
    const _setLanguage =(newLanguage:string)=>{
        localStorage.setItem("newLanguage", newLanguage)
        const _actualLanguage = facadeLangaugeMap[newLanguage] || newLanguage
        if (_actualLanguage!=undefined){
            setFacadeLanguage(newLanguage)
        }

        changeLanguage(_actualLanguage)
    }

    const reloadCSS = () => {
        const link = document.getElementById('cssStyle') as HTMLLinkElement;
        if (link) {
          const newHref = link.getAttribute('href')?.split('?')[0] + '?v=' + new Date().getTime(); // Cache-busting query param
          link.setAttribute('href', newHref);
        }
      };

    const reloadPage=()=>{
    // Force reload with a cache-busting URL (to bypass the cache)
    const currentUrl = window.location.href.split('?')[0]; // Remove any existing query parameters
    const cacheBustedUrl = `${currentUrl}?v=${new Date().getTime()}`; // Add cache-busting query param
    window.location.replace(cacheBustedUrl);
    }

    const setLanguage =(newLanguage:string)=>{
        localStorage.setItem("newLanguage", newLanguage)
        _setLanguage(newLanguage)
        //reloadCSS()
        //reloadPage()
    }

    React.useEffect(()=>{
        const newLanguage = localStorage.getItem("newLanguage")
        if (newLanguage){
            _setLanguage(newLanguage)
        }
    },[])

    return (
        <LanguageConetxt.Provider value={{language, setLanguage, facadeLanguage}}>
            {children}
        </LanguageConetxt.Provider>
    )
}

export const useLanguage=()=>{
    const context = useContext(LanguageConetxt);
    if (context === undefined) {
      throw new Error("useLanguage not in LanguageProvider");
    }
    return context;
}