export const APPBAR_LANGUAGE: { [key: string]: string; } = {
    "english": "ENGLISH",
    "chinese": "简体中文",
    "chinese_traditional": "繁體中文"
};

export const SEARCH_PLACEHOLDER:{[key:string]:string}={
    "english": "peopele / food / cat",
    "chinese": "人物 / 迪士尼 / 睡觉"
};
export const APPBAR_TABS: { [key: string]: { [key: string]: string; }; } = {
    "english": {
        "art": "Art",
        "stack": "Tech Stack",
        "contact": "Say Hello"
    },
    "chinese": {
        "art": "作品集",
        "stack": "技术栈",
        "contact": "联络我"
    }
}

// check ths still used? 
export const SEARCH_CATEGORY: { [key: string]: { [key: string]: string; }; } = {
    "english": {
        "design": "Design",
        "watercolour": "Watercolour",
        "digital": "Digital",
        "clay": "Others"
    },
    "chinese": {
        "design": "设计",
        "watercolour": "水彩画",
        "digital": "电子",
        "clay": "其他"
    }
};

