import React from 'react';
import { Typography, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

interface ErrorComponentProps{
    errorHeader : String
    errorMessage : String
}


export const ErrorComponent = (props:ErrorComponentProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100vh"
            width="100%"
        >
            <Typography variant="h5" color="error" align="center">
                <Box display="flex" alignItems="center"> 
                    <ErrorIcon color="error" fontSize="large" /> 
                    {props.errorHeader}
                </Box>
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center">
                {props.errorMessage}
            </Typography>
        </Box>
    );
};

export default ErrorComponent;
