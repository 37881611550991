import { AppAppBar } from "../components/AppBar";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useLanguage } from "../contexts/LanguageContext";
import { TECHSTACK_FEATURES, TECHSTACK_TECH_STACK, TECHSTACK_HEADERS } from "../constants/language-techstack";
import { getFontVariantEastAsian } from "../utils/language-util";
import { getRandomKeywords } from "../constants/general";

const ImageComponent = (props: any) => {
  return (
    <div>
      {props.imgUrl&&<Box
        sx={{
          height: "700px",
          width: "100%",
          position: "relative",
          color:"red",
        }}
      ><Box
        component="img"
        src={props.imgUrl}
        alt={getRandomKeywords()}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      /></Box>}
    </div>
  );
};

const MyHeader = (props: any) => {
  return (
    <Grid container spacing={2} marginBottom={5}>
      <Grid item xs={12} md={5}></Grid>
      <Grid item xs={12} md={6} alignItems="left">
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'left', // Center content on extra-small screens (phones)
              sm: 'flex-start' // Align content to the start on small screens and up
            },
            marginLeft:{xs:3},
            borderLeft: "2px solid black", // Change thickness, style, and color as needed
            padding: "15px", // Optional: Add padding to see the border effect
            // fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage)
          }}
        >
          <Typography variant="h4" fontWeight={600}> 
            {props.header}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const ParagraphComponents = (props: any) => {
  return (
    <Box sx={{ marginRight: 1, marginLeft: 1, marginBottom: 10 }}>
      {props.paragraphs.map((para: string, index: string) => (
          <Typography
            variant="body1"
            paragraph
            align="justify"
            marginBottom={4}
            sx={{fontWeight:{"md":100, "xs":300}}}
            key={index}
          >
            {para}
          </Typography>
      ))}
    </Box>
  );
};


const Features = () => {
  const {language} = useLanguage()
  const data = TECHSTACK_FEATURES[language];

  return (
    <div>
      {data.map((info, index) => (
        <Grid container spacing={2} sx={{marginBottom:{xs:1, md:10}}} key={index}>
          <Grid item xs={12} md={5}>
            <ImageComponent imgUrl={info.imgUrl} />
          </Grid>
          <Grid item xs={12} md={6}>
          <Box sx={{ marginRight: 1, marginLeft: 1}}>
            <Typography
              variant="h6"
              paragraph
              align="left"
              sx={{ fontWeight: "600"}}
              
            >
              {info.requirement}
            </Typography>
            {
              info.designConsiderations.map((content,index)=>(
                (
                  <Typography sx={{fontWeight:{"md":100, "xs":300}}} marginBottom={2} align="justify" key={index}>
                    {content}
                  </Typography>
                )
              ))

            }</Box>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export const TechStack = () => {
  const {language, facadeLanguage} = useLanguage()
  return (
    <div>
      <AppAppBar />
      <Box sx={{fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage)}}>
      {/* {language==="english"&&<Typography variant="caption" paragraph align="center" sx={{fontWeight:{"md":100, "xs":300}}} marginBottom={5} >
        This page basically contains everything about this site.. please don’t
        hack me! :) Open to any suggestion and discussion about below!
      </Typography>} */}
      <br/>
      <MyHeader header={TECHSTACK_HEADERS[language]['techStack']} />
      <Grid container spacing={2} sx={{marginBottom:{xs:1, md:10}}}>
        <Grid item xs={12} md={5}>
          <ImageComponent imgUrl="images/techstack/techstack.png" />
        </Grid>
        <Grid item xs={12} md={6} alignItems="center" >
          <ParagraphComponents
            paragraphs={TECHSTACK_TECH_STACK[language]}
          />
        </Grid>
      </Grid>
      <MyHeader header={TECHSTACK_HEADERS[language]['features']} />
      <Features />
      </Box>
    </div>
  );
};
