export const CONTACT_INTRO: { [key: string]: string[] } = {
  english: [
    "From an early age, I've always enjoyed various forms of art,whether it was drawing, painting, carving, or designing. Art has always been a cherished hobby, a relaxing escape and a joyful way to channel my creativity and express myself.",
    "Later on, I discovered a passion for software engineering,which eventually led me to a full-time role where I get to explore my curiosity and solve problems through technology",
    "So here we are! The love for both art and technology inspired the creation of this website. I hope it serves as a platform for more future explorations and as a constant reminder of why I love what I do! I hope you enjoy what you find here!",
  ],
  chinese: [
    "从小，我就对各种形式的艺术充满了兴趣，无论是绘画、油画、雕刻还是设计。艺术一直是我珍视的爱好，它让我在放松中表达创造力和自我",
    "随着时间的推移，我发现自己对软件工程产生了浓厚的兴趣。这份热情让我找到了全职工作，在那里我能够运用技术来探索新领域和解决各种问题",
    "因此，我创建了这个网站，希望能将我对艺术和科技的热爱融汇在一起。希望它成为我未来探索的新平台，同时也时刻提醒我为何热爱自己所做的事情。希望你能在这里找到你喜欢的内容",
  ],
};

export const CONTACT_FORM: { [key: string]: { [key: string]: string } } = {
  english: {
    header: "HELLO HELLO",
    name: "Name",
    contact: "Email / Social (email for receipt)",
    message: "Leave a message and i will get back to you !",
    send: "send",
    sending: "sending..",
    sendSuccessMsg: "Message Sent!",
    tooManyRequestWarning: "Why so many messages? Find me somewhere else!",
    sendFailure:"Message Not Sent !",
  },
  chinese: {
    header: "你好!",
    name: "尊称",
    contact: "邮件/联络方式",
    message: "请留言，我会尽快回复你",
    send: "发送",
    sending: "发送中..",
    sendSuccessMsg: "发送了!",
    tooManyRequestWarning: "太多次了。。",
    sendFailure:"发送失败!",
  },
};


