import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    }
  },
  components:{
    MuiAppBar:{
        styleOverrides:{
            colorPrimary:{
                backgroundColor: 'white', boxShadow: 'none' 
            }
        }
    },

  }
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  }
});

export default darkTheme;

