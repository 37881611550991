
export const TECHSTACK_HEADERS: { [key: string]: { [key: string]: string; }; } = {
  english: {
    techStack: "Tech Stack",
    features: "Feature Design Considerations",
  },
  chinese: {
    techStack: "技术栈",
    features: "功能设计",
  },
};

export const TECHSTACK_TECH_STACK: { [key: string]: string[]; } = {
  english: [
    "This website is hosted on an AWS infrastructure, utilising a t2.micro EC2 instance alongside a t3.small OpenSearch instance. Nginx serves as the reverse proxy server, while Docker facilitates deployment and component management. Leveraging cloud technology allows for efficient resource usage and provides the flexibility to scale as needed",
    "For the frontend, I opted for React + TypeScript for the robust set of UI tools and its versatility in integrating with various backend frameworks, including Flask and GraphQL. React's component-based architecture and TypeScript's type safety provide a strong and scalable development environment for exploration. As for the core visual elements, I choose to go with MUI as the primary component library. MUI's simple and minimalistic design gives a clean, modern look that aligns well with the website’s overall aesthetic that I was looking for",
    "On the backend, Flask was selected for its lightweight nature and suitability for managing straightforward REST API tasks, such as health checks and form processing. To manage detailed artwork information effectively, GraphQL was implemented, allowing for flexible and efficient data queries",
    "For data storage and management, PostgreSQL was chosen, as the relational database enables easy segregation and joining of information, such as different art pieces and their associated metadata. Given that each piece includes free-text descriptions, Elasticsearch was integrated to facilitate efficient searching of the contents"
  ],
  chinese: [
    "网站托管在一个 AWS t2.micro EC2 和一个 t3.small OpenSearch 服务器上。使用 Nginx 作为反向代理服务器，Docker 进行部署和组件管理。使用 AWS 使得在有限资源下进行探索成为可能，并且在需要时可以灵活扩展",
    "在前端，我选择了 React 和 TypeScript，因为它们提供了强大的 UI 工具集，并且在与各种后端框架（如 Flask 和 GraphQL）集成时具有很高的灵活性。React 的组件化架构和 TypeScript 的类型安全为探索提供了一个强大且可扩展的开发环境。至于核心视觉元素，我选择了 MUI 作为主要组件库。MUI 的简洁设计赋予了网站现代而清新的外观",
    "在数据存储和管理方面，我选择了 Postgres 作为数据库，以存储信息，因为关系型数据库便于不同信息（如不同艺术作品及其元数据）的分离和连接。由于每件作品包含一些自由文本描述，我希望能轻松搜索它们，因此使用了 Elasticsearch",
  ],
};
export const TECHSTACK_FEATURES: {
  [key: string]: {
    requirement: string;
    designConsiderations: string[];
    imgUrl?: string;
  }[];
} = {
  english: [
    {
      requirement: "Artworks and Their Stories",
      designConsiderations: [
        "Each artwork comes with a title and a description. On the homepage, you’ll see just the thumbnail and title for a quick look. Click on the thumbnail to open a dialog box, and you’ll reveal all the detailed information and the story behind each piece",
        "To enhance the viewing experience, the homepage design is optimised for different devices. The web version displays three artworks per row, while the mobile version has just one artwork per row for easier browsing",
      ],
      imgUrl: "images/techstack/pages.png",
    },
    {
      requirement:"Enhanced Search and Filtering of Artworks through thematic content or artistic medium",
      designConsiderations: [ 
        "Each piece is tagged with relevant metadata to improve search and filtering capabilities. This includes 'Tags' (e.g., Animal, Food), which are keywords associated with the artwork and displayed at the bottom of each thumbnail. Additionally, 'Categories' (e.g., Watercolour, Digital Painting) classify the artwork by type, with selected categories featured below the search bar on the homepage. Metadata-based filtering is implemented using PostgreSQL database queries",
        "To broaden search results, the title and description fields of each artwork are searchable through free-text search. This functionality is powered by Elasticsearch, with OpenSearch utilised in the AWS environment for seamless integration and performance",
        "Furthermore, query correction feature leverages Elasticsearch’s suggestion function to offer alternative queries and correct typographical errors, thereby enhancing the search experience and improving search accuracy.",
      ],
      imgUrl: "images/techstack/search.png",
    },
    {
      requirement: "As of all websites, there should be a way to get in touch",
      designConsiderations: [
        "A contact form was introduced to allow people to reach out to me - no detours to other channels required!",
        "Whenever someone fills out the form, I get an email alert, and if their email is valid, they'll receive a friendly acknowledgment in return",
        "To keep the servers happy and avoid spammy messages, flask limiter was used to limit the number of request made in a within a certain timeframe",
      ],
      imgUrl: "images/techstack/contact.png",
    },
    {
      requirement: "In another language.. (Work in progress)",
      designConsiderations: [
        "Inspired by my dad who complains that he can't understand a single thing here, this site is now adapted for both English and Chinese, featuring both Traditional and Simplified Chinese just for fun! I might wanna add in Catonese as well soon to test the adaptability and scaling in the language aspect",
        "I decided to skip using a translation package for on-the-fly translations. While it is pretty handy, they often miss the charming nuances that gives a language its character. Instead, to adapt the site for Chinese, I Google translated the original English data into Chinese, made some adjustements and stored it in separate tables in the database",
        "To keep things simple, the switch between Simplified and Traditional Chinese is purely for display — both versions share the same content. The only challenge observed with this appoarch is that on some older browsers  or safari, the toggling between tradtional and simplfied chiense may or may not take effect",
        "More exploration in the future on Chinese search capabilities with Elasticsearch as well as region based default language configuration"
      ],
      imgUrl: "images/techstack/multi_lang.png",
    },
    {
      requirement: "Improve collection management process (Future plan)",
      designConsiderations: [
        "Currently, any updates to the collection is done at the backend, a UI based collection management component would be ideal",
        "Each addition / removal / update of artworks will need to be synced in across image collection, database and Elasticsearch index through a process that also takes into consideration any point of failure during the automated update",
      ],
    },
    {
      requirement: "Enhancements on search and filtering (Future plan)",
      designConsiderations: [
        "Tokenisation is something i plan to implement soon, by segmenting text into discrete tokens, it will refine the free-text search experience, enhancing accuracy, flexibility, and efficiency in search results",
        "Explore the possibility of generating word cloud using the images and use the generated word cloud for searching. This mechanism should complement the previous point, ensuring that it is triggered appropriately whenever new artwork is added",
        "To have enhanced sorting / filtering functions with dedicated filtering panel",
      ],
    },
  ],
  chinese: [
    {
      requirement: "作品与它们的故事",
      designConsiderations: [
        "每件作品都附有标题和说明。主页上只显示每件作品的缩略图和标题，方便快速查看",
        "从缩略图打开对话框即可看到详细信息和相关故事",
        "为了提升浏览体验，主页设计针对不同设备进行了优化。网页版每行显示三件作品，而移动版每行显示一件作品",
      ],
      imgUrl: "images/techstack/pages.png",
    },
    {
      requirement: "需要根据主题内容或艺术媒介等特征搜索作品",
      designConsiderations: [
        "每件作品都标有相关元数据，以提高搜索和过滤能力。其中包括“标签”（例如，动物、食物），它们是与作品相关的关键词，显示在每个缩略图的底部。此外，“类别”（例如，水彩画、数码绘画）按类型对作品进行分类，选定的类别显示在主页的搜索栏下方。基于元数据的过滤是使用 PostgreSQL 数据库查询来完成的",
        "为了扩大搜索结果，每件作品的标题和描述字段都可以通过自由文本搜索进行搜索。此功能由 Elasticsearch 提供支持，并在 AWS 环境中使用 OpenSearch 实现无缝集成和性能 （目前只限英文）",
        "此外，查询更正功能利用 Elasticsearch 的建议功能来提供替代查询并更正印刷错误，从而增强搜索体验并提高搜索准确性",
      ],
      imgUrl: "images/techstack/search.png",
    },
    {
      requirement: "联系方式",
      designConsiderations: [
        "加入了联系表格，让访问者无需重定向到其他渠道即可联系到我",
        "如果提供的电子邮件有效，则每次提交表单都会触发一封电子邮件提醒给我自己，并向发件人发送一封确认电子邮件",
        "为了防止向服务器发送垃圾邮件，使用 Flask 限制器来限制在特定时间范围内发出的请求数量",
      ],
      imgUrl: "images/techstack/contact.png",
    },
    {
      requirement: "双语 (目前进行中)",
      designConsiderations: [
        "由于我爸看不懂这个网站，启发我尝试将网站打造成双语系统， 目前支持中英双语。中文方面包括简体和繁体。我可能还想添加粤语，以测试语言方面的适应性和可扩展",
        "我先将原本英文的内容翻译成中文，再储存进数据库中不同的表格里。而繁体和简体字的切换则存粹是视觉层面的，没有内容上的差异。唯一观察到的情况是，在某些旧版本的浏览器或 Safari 中，简体与繁体中文的切换可能无法如预期般顺利",
        "未来将进一步探索 Elasticsearch 的中文搜索功能，以及根据访客所在地区设置默认语言设置的可能性",
      ],
      imgUrl: "images/techstack/multi_lang.png",
    },
    {
      requirement: "改善收款管理流程（未来计划)",
      designConsiderations: [
        "目前，对收藏的任何更新都是在后端完成的，理想的情况是实现一个用户界面以便更新数据",
        "每次添加/删除/更新作品都需要在图像集合、数据库和 Elasticsearch 索引之间进行同步，这个过程还会考虑到自动更新过程中的任何故障点",
      ],
    },
    {
      requirement: "增强搜索和过滤功能（未来计划)",
      designConsiderations: [
        "打算实现分词，将文本划分为离散的标记，这将优化自由文本搜索体验，提高搜索结果的准确性、灵活性和效率",
        "探索使用图像生成词云的可能性，并使用生成的词云进行搜索。此机制应与上一点相辅相成，确保每当添加新作品时都能适当触发该机制",
        "使用专用过滤面板增强排序和过滤功能",
      ],
    },
  ],
};
