import React from "react";
import { AppAppBar } from "../components/AppBar";
import { Grid, Typography, Avatar, Box, Divider } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { postSubmitContract, getHealth } from "../api";
import { CONTACT_INTRO, CONTACT_FORM } from "../constants/language-contact";
import { useLanguage } from "../contexts/LanguageContext";
import { getFontVariantEastAsian } from "../utils/language-util";
import { getRandomKeywords } from "../constants/general";

const ContactForm = (props: any) => {
  const [formData, setFormData] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);
  const [sending, setSending] = React.useState<boolean>(false);
  const [submissionErrorMsg, setSubmissionErrorMsg] =
    React.useState<string>("");
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const {language, facadeLanguage} = useLanguage()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSending(true);
    postSubmitContract(formData).then((res) => {
      if (res.status === 429) {
        setSubmissionErrorMsg(CONTACT_FORM[language]["tooManyRequestWarning"]);
        setSubmitted(false);
        setSending(false);
      } else if (res.status === 200) {
        setSubmitted(true);
        setSubmissionErrorMsg("");
        setSending(false);
      }

      if (formRef.current) {
        formRef.current.reset();
      }
    });
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Box>
      {submissionErrorMsg !== "" && (
        <Alert severity="warning">
          {" "}
          {CONTACT_FORM[language]["sendFailure"]}{submissionErrorMsg}
        </Alert>
      )}
      {submitted === true && sending !== true && (
        <Alert severity="success">
          {" "}
          {CONTACT_FORM[language]["sendSuccessMsg"]}
        </Alert>
      )}
      {sending === true && <Alert severity="info"> {CONTACT_FORM[language]["sending"]} </Alert>}
      <form
        onSubmit={handleSubmit}
        style={{ marginTop: "15px", width: "100%" }}
        ref={formRef}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label={CONTACT_FORM[language]["name"]}
              variant="outlined"
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="contact"
              label={CONTACT_FORM[language]["contact"]}
              variant="outlined"
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="message"
              required
              label={CONTACT_FORM[language]["message"]}
              variant="outlined"
              multiline
              rows={10}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={sending}
              sx={{
                color: "white",
                backgroundColor: "black",
                width: 100,
                fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage),
                "&:hover": {
                  backgroundColor: "#82A197",
                  transition: "background-color 0.3s ease, transform 0.3s ease",
                  transform: "scale(1.1)",
                },
              }}
            >
              {sending
                ? CONTACT_FORM[language]["sending"]
                : CONTACT_FORM[language]["send"]}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const Contact: React.FC = () => {
  const [serverOk, setServerOk] = React.useState(false);
  const [loading, setLoading] = React.useState<undefined | boolean>(undefined);
  const { language, facadeLanguage } = useLanguage();

  const handleLinkClick = () => {
    window.open("https://www.linkedin.com/in/huangdajing/", "_blank");
  };

  React.useEffect(() => {
    setLoading(true);
    getHealth()
      .then((data) => {
        setServerOk(data["status"] === "OK");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <AppAppBar />

      <Box
        p={4}
        sx={{ 
          fontVariantEastAsian: getFontVariantEastAsian(facadeLanguage) }}
      >
        {loading === false ? (
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={serverOk ? 4 : 12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={getRandomKeywords()}
                src="/images/me.png"
                style={{ width: "150px", height: "150px" }}
              />
              <Typography
                variant="h4"
                style={{ marginBottom: "5px" }}
                align="justify"
              >
                DJ
              </Typography>
              {CONTACT_INTRO[language].map((para, index) => (
                <Typography
                
                  variant="body2"
                  paragraph
                  align="justify"
                  sx={{ fontWeight: { md: 100, xs: 300 } }}
                  key={index}
                >
                  {para}
                </Typography>
              ))}
              <IconButton
                onClick={handleLinkClick}
                style={{ fontSize: "10rem" }}
              >
                <LinkedInIcon sx={{ color: "#0077B5", fontSize: 34 }} />
              </IconButton>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              style={{ paddingLeft: "50px" }}
            />

            {serverOk && (
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                >
                  {CONTACT_FORM[language]["header"]}
                </Typography>
                <ContactForm />
              </Grid>
            )}
          </Grid>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Contact;
