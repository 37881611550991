import React from "react";
import { AppAppBar } from "../components/AppBar";
import { ArtworkInterface } from "../components/Artwork";
import { ArtWorks, ArtworksDefault } from "../components/Artworks";
import { SearchBar } from "../components/Search";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ARTWORKS,
  GET_ARTWORKS_BY_CATEGORIES,
  GET_ALL_ARTWORKS,
  GET_ARTWORKS_BY_TAGS,
} from "../graphql-queries";
import { getHealth } from "../api";
import { useLanguage } from "../contexts/LanguageContext";

export const Home: React.FC = () => {
  const [serverOk, setServerOk] = React.useState(true);
  const [artworks, setArtworks] = React.useState<ArtworkInterface[]|undefined>(undefined);
  const { language } = useLanguage();

  // Query artworks generally
  const [
    getSearchedArtworks,
    { loading: artworkByFreeTxtLoading, error: artworkByFreeTxtErorr },
  ] = useLazyQuery(GET_ARTWORKS, {
    onCompleted: (data) => {
      setArtworks(data.searchArtwork);
    },
  });

  // Query artworks by categories
  const [
    getArtworkByCategories,
    { loading: artworkByCategoriesLoading, error: artworkByCategoriesError },
  ] = useLazyQuery(GET_ARTWORKS_BY_CATEGORIES, {
    onCompleted: (data) => {
      setArtworks(data.searchArtworkCategories);
    },
  });

  const [
    getArtworkByTags,
    { loading: artworkByTagsLoading, error: artworkByTagsError },
  ] = useLazyQuery(GET_ARTWORKS_BY_TAGS, {
    onCompleted: (data) => {
      setArtworks(data.searchArtworkTags);
    },
  });

  const [
    getAllArtworks,
    { loading: allArtworksLoading, error: allArtworksError },
  ] = useLazyQuery(GET_ALL_ARTWORKS, {
    onCompleted: (data) => {
      setArtworks(data.allArtworks);
    },
  });

  // On click search button
  const handleOnclickSearch = (value: string) => {
    setArtworks([]);
    getSearchedArtworks({
      variables: { language: language, query: value },
      fetchPolicy: "network-only",
    });
  };

  // On click category buttons
  const handleOnclickSearchCategories = (value: string) => {
    setArtworks([]);
    getArtworkByCategories({
      variables: { language: language, query: value },
      fetchPolicy: "network-only",
    });
  };

  // On click tag buttons
  const handleOnClickTag = (value: string) => {
    setArtworks([]);
    getArtworkByTags({
      variables: { query: value, language: language },
      fetchPolicy: "network-only",
    });
  };

  React.useEffect(() => {
    getHealth().then((data) => {
      setServerOk(data["status"] === "OK");
    });
    getAllArtworks({
      variables: { language: language },
      fetchPolicy: "network-only",
    });
  }, []);

  React.useEffect(() => {
    setArtworks([]);
    getAllArtworks({
      variables: { language: language },
      fetchPolicy: "network-only",
    });
  }, [language]);

  return (
    <div>
      <AppAppBar />
      {serverOk && artworks!==undefined&&(
        <SearchBar
          handleOnclickSearch={handleOnclickSearch}
          handleOnclickCategories={handleOnclickSearchCategories}
          artworkData={artworks}
        />
      )}
      {serverOk ? artworks!==undefined&&(
        <ArtWorks
          artworks={artworks}
          error={allArtworksError}
          searching={
            artworkByFreeTxtLoading ||
            artworkByCategoriesLoading ||
            allArtworksLoading ||
            artworkByTagsLoading
          }
          handleOnClickTag={handleOnClickTag}
        />
      ) : (
        <ArtworksDefault />
      )}
    </div>
  );
};

export default Home;
